import React from 'react';
import './styles.css'; // Adicionaremos alguns estilos específicos

const TermsOfService: React.FC = () => {
  return (
    <div className="terms-of-service-container">
      <h1>Terms of Service</h1>
      <p>Effective Date: August 28, 2024</p>

      <section>
        <h2>1. Agreement to Terms</h2>
        <p>
          By accessing or using our website, you agree to be bound by these Terms of Service. 
          If you disagree with any part of the terms, you may not access the service.
        </p>
      </section>

      <section>
        <h2>2. Use of Our Service</h2>
        <p>
          You agree not to misuse our service. This includes activities such as hacking, spamming, or any other illegal activity. 
          Any violation may result in suspension or termination of your account.
        </p>
      </section>

      <section>
        <h2>3. Account Responsibility</h2>
        <p>
          You are responsible for maintaining the confidentiality of your account and password. You agree to notify us immediately 
          of any unauthorized use of your account.
        </p>
      </section>

      <section>
        <h2>4. Modifications to Service</h2>
        <p>
          We reserve the right to modify or discontinue the service at any time, with or without notice. We are not liable for any 
          changes or interruptions to the service.
        </p>
      </section>

      <section>
        <h2>5. Governing Law</h2>
        <p>
          These terms are governed by and construed in accordance with the laws of [Your Country/State]. Any disputes arising 
          under these terms will be subject to the exclusive jurisdiction of the courts in [Your City].
        </p>
      </section>
    </div>
  );
};

export default TermsOfService;
