import React from 'react';
import './styles.css'; // Adicionaremos alguns estilos específicos

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="privacy-policy-container">
        <h1>Política de privacidade</h1>
        <p>Última atualização: Agosto 28, 2024</p>
        <p>Esta Política de Privacidade descreve as nossas políticas e procedimentos sobre a recolha, utilização e divulgação das suas informações quando utiliza o Serviço e informa-o sobre os seus direitos de privacidade e a forma como a lei o protege</p>
        <p>Utilizamos os dados pessoais do Utilizador para fornecer e melhorar o Serviço. Ao utilizar o Serviço, o Utilizador concorda com a recolha e utilização de informações de acordo com a presente Política de Privacidade. Esta Política de Privacidade foi criada com a ajuda do <a href="https://www.termsfeed.com/privacy-policy-generator/" >Gerador de política de privacidade</a>.</p>
        <h2>Interpretação e definições</h2>
        <h3>Interpretação</h3>
        <p>As palavras cuja letra inicial é maiúscula têm significados definidos nas seguintes condições. As definições seguintes têm o mesmo significado, independentemente do facto de aparecerem no singular ou no plural.</p>
        <h3>Definições</h3>
        <p>Para efeitos da presente Política de Privacidade:</p>
        <ul>
        <li>
        <p><strong>Conta</strong> significa uma conta única criada para o Utilizador aceder ao nosso Serviço ou a partes do nosso Serviço</p>
        </li>
        <li>
        <p><strong>Filial</strong> significa uma entidade que controla, é controlada por ou está sob controlo comum de uma parte, em que “controlo” significa a propriedade de 50% ou mais das acções, participações ou outros títulos com direito a voto para a eleição de administradores ou outra autoridade de gestão.</p>
        </li>
        <li>
        <p><strong>Aplicação</strong> refere-se ao EGSolutions, o programa de software fornecido pela Empresa.</p>
        </li>
        <li>
        <p><strong>Empresa</strong> (referido como “a Empresa”, “Nós”, “Nos” ou “Nosso” no presente Contrato) refere-se à EGSolutions.</p>
        </li>
        <li>
        <p><strong>País</strong> refere-se a:  Portugal</p>
        </li>
        <li>
        <p><strong>Dispositivo</strong> significa qualquer dispositivo que possa aceder ao Serviço, como um computador, um telemóvel ou um tablet digital.</p>
        </li>
        <li>
        <p><strong>Dados pessoais</strong> é qualquer informação relativa a uma pessoa identificada ou identificável.</p>
        </li>
        <li>
        <p><strong>Serviço</strong> refere-se à aplicação.</p>
        </li>
        <li>
        <p><strong>Fornecedor de serviços</strong> significa qualquer pessoa singular ou colectiva que processe os dados em nome da Empresa. Refere-se a empresas terceiras ou indivíduos contratados pela Empresa para facilitar o Serviço, para fornecer o Serviço em nome da Empresa, para executar serviços relacionados com o Serviço ou para ajudar a Empresa a analisar a forma como o Serviço é utilizado.</p>
        </li>
        <li>
        <p><strong>Serviço de redes sociais de terceiros</strong> refere-se a qualquer website ou a qualquer website de rede social através do qual um Utilizador pode iniciar sessão ou criar uma conta para utilizar o Serviço.</p>
        </li>
        <li>
        <p><strong>Dados de utilização</strong> refere-se aos dados recolhidos automaticamente, quer gerados pela utilização do Serviço quer pela própria infraestrutura do Serviço (por exemplo, a duração de uma visita à página).</p>
        </li>
        <li>
        <p><strong>Tu</strong> significas o indivíduo que acede ou utiliza o Serviço, ou a empresa ou outra entidade legal em nome da qual esse indivíduo está a aceder ou a utilizar o Serviço, conforme aplicável.</p>
        </li>
        </ul>
        <h2>Recolha e utilização dos seus dados pessoais</h2>
        <h3>Tipos de dados recolhidos</h3>
        <h4>Dados pessoais</h4>
        <p>Ao utilizar o nosso Serviço, podemos pedir-lhe que nos forneça determinadas informações de identificação pessoal que podem ser utilizadas para o contactar ou identificar. As informações de identificação pessoal podem incluir, mas não estão limitadas a:</p>
        <ul>
        <li>
        <p>Endereço de correio eletrónico</p>
        </li>
        <li>
        <p>Nome próprio e apelido</p>
        </li>
        <li>
        <p>Número de telefone</p>
        </li>
        <li>
        <p>Endereço, Estado, Província, Código Postal, Cidade</p>
        </li>
        <li>
        <p>Dados de utilização</p>
        </li>
        </ul>
        <h4>Dados de utilização</h4>
        <p>Os Dados de Utilização são recolhidos automaticamente quando se utiliza o Serviço.</p>
        <p>Os Dados de Utilização podem incluir informações como o endereço de Protocolo de Internet do seu Dispositivo (por exemplo, endereço IP), tipo de browser, versão do browser, as páginas do nosso Serviço que visita, a hora e a data da sua visita, o tempo passado nessas páginas, identificadores únicos de dispositivos e outros dados de diagnóstico.</p>
        <p>Quando o Utilizador acede ao Serviço através de um dispositivo móvel, podemos recolher determinadas informações automaticamente, incluindo, entre outras, o tipo de dispositivo móvel que utiliza, a ID exclusiva do seu dispositivo móvel, o endereço IP do seu dispositivo móvel, o sistema operativo do seu dispositivo móvel, o tipo de navegador de Internet móvel que utiliza, identificadores exclusivos de dispositivos e outros dados de diagnóstico.</p>
        <p>Também podemos recolher informações que o seu browser envia sempre que visita o nosso Serviço ou quando acede ao Serviço por ou através de um dispositivo móvel.</p>
        <h4>Informações de serviços de redes sociais de terceiros</h4>
        <p>A Empresa permite que o Utilizador crie uma conta e inicie sessão para utilizar o Serviço através dos seguintes Serviços de Redes Sociais de Terceiros:</p>
        <ul>
        <li>Google</li>
        <li>Facebook</li>
        <li>Instagram</li>
        <li>Twitter</li>
        <li>LinkedIn</li>
        </ul>
        <p>Se o Utilizador decidir registar-se através de um Serviço de Redes Sociais de Terceiros ou conceder-nos acesso a esse Serviço, poderemos recolher dados pessoais que já estejam associados à conta do Serviço de Redes Sociais de Terceiros, como o nome, o endereço de e-mail, as actividades ou a lista de contactos do Utilizador associados a essa conta.</p>
        <p>O Utilizador pode também ter a opção de partilhar informações adicionais com a Empresa através da conta do seu Serviço de Redes Sociais de Terceiros. Se optar por fornecer essas informações e Dados Pessoais, durante o registo ou de outra forma, está a dar permissão à Empresa para os utilizar, partilhar e armazenar de uma forma consistente com esta Política de Privacidade.</p>
        <h3>Utilização dos seus dados pessoais</h3>
        <p>A Empresa pode utilizar os Dados Pessoais para as seguintes finalidades:</p>
        <ul>
        <li>
        <p><strong>Para fornecer e manter o nosso Serviço</strong>, incluindo para monitorizar a utilização do nosso Serviço.</p>
        </li>
        <li>
        <p><strong>Para gerir a sua conta:</strong> para gerir o seu registo como utilizador do Serviço. Os Dados Pessoais que fornece podem dar-lhe acesso a diferentes funcionalidades do Serviço que estão disponíveis para si enquanto utilizador registado.</p>
        </li>
        <li>
        <p><strong>Para a execução de um contrato:</strong> o desenvolvimento, o cumprimento e a execução do contrato de compra dos produtos, artigos ou serviços que adquiriu ou de qualquer outro contrato celebrado connosco através do Serviço.</p>
        </li>
        <li>
        <p><strong>Para o contactar:</strong> Contactar o Utilizador por e-mail, chamadas telefónicas, SMS ou outras formas equivalentes de comunicação eletrónica, como notificações push de uma aplicação móvel sobre actualizações ou comunicações informativas relacionadas com as funcionalidades, produtos ou serviços contratados, incluindo as actualizações de segurança, quando necessário ou razoável para a sua implementação.</p>
        </li>
        <li>
        <p><strong>Para fornecer ao Utilizador</strong> com notícias, ofertas especiais e informações gerais sobre outros bens, serviços e eventos que oferecemos e que são semelhantes aos que o utilizador já adquiriu ou solicitou, exceto se o utilizador tiver optado por não receber essas informações.</p>
        </li>
        <li>
        <p><strong>Para gerir os seus pedidos:</strong> Para atender e gerir os pedidos que o Utilizador nos faz.</p>
        </li>
        <li>
        <p><strong>Para transferências de empresas:</strong>Podemos utilizar as suas informações para avaliar ou realizar uma fusão, alienação, reestruturação, reorganização, dissolução ou outra venda ou transferência de alguns ou de todos os nossos activos, quer como uma empresa em funcionamento, quer como parte de uma falência, liquidação ou processo semelhante, em que os Dados Pessoais detidos por nós sobre os utilizadores do nosso Serviço estejam entre os activos transferidos.</p>
        </li>
        <li>
        <p><strong>Para outros fins</strong>: Podemos utilizar as informações do Utilizador para outros fins, tais como análise de dados, identificação de tendências de utilização, determinação da eficácia das nossas campanhas promocionais e para avaliar e melhorar o nosso Serviço, produtos, serviços, marketing e a sua experiência.</p>
        </li>
        </ul>
        <p>Podemos partilhar as suas informações pessoais nas seguintes situações:</p>
        <ul>
        <li><strong>Com os prestadores de serviços:</strong>Podemos partilhar as suas informações pessoais com os Prestadores de Serviços para monitorizar e analisar a utilização do nosso Serviço, para o contactar.</li>
        <li><strong>Para transferências de empresas:</strong> Podemos partilhar ou transferir as Suas informações pessoais em ligação com, ou durante as negociações de, qualquer fusão, venda de activos da Empresa, financiamento ou aquisição da totalidade ou de uma parte do Nosso negócio a outra empresa.</li>
        <li><strong>Com afiliados:</strong> Poderemos partilhar as suas informações com as nossas filiais, caso em que exigiremos que essas filiais respeitem esta Política de Privacidade. As filiais incluem a nossa empresa-mãe e quaisquer outras subsidiárias, parceiros de joint-venture ou outras empresas que controlamos ou que estão sob controlo comum connosco.</li>
        <li><strong>Com parceiros comerciais:</strong> Podemos partilhar as suas informações com os nossos parceiros comerciais para lhe oferecer determinados produtos, serviços ou promoções.</li>
        <li><strong>Com outros utilizadores:</strong> quando o Utilizador partilha informações pessoais ou interage de outra forma nas áreas públicas com outros utilizadores, essas informações podem ser vistas por todos os utilizadores e podem ser distribuídas publicamente no exterior. Se o Utilizador interagir com outros utilizadores ou se registar através de um Serviço de Redes Sociais de Terceiros, os seus contactos no Serviço de Redes Sociais de Terceiros poderão ver o seu nome, perfil, fotografias e descrição da sua atividade. Da mesma forma, outros utilizadores poderão ver descrições da sua atividade, comunicar consigo e ver o seu perfil.</li>
        <li><strong>Com o seu consentimento</strong>: Podemos divulgar as suas informações pessoais para qualquer outro fim com o seu consentimento.</li>
        </ul>
        <h3>Retenção dos seus dados pessoais</h3>
        <p>A Empresa reterá os Dados Pessoais do Utilizador apenas durante o tempo necessário para os fins definidos na presente Política de Privacidade. Iremos reter e utilizar os seus Dados Pessoais na medida do necessário para cumprir as nossas obrigações legais (por exemplo, se formos obrigados a reter os seus dados para cumprir a legislação aplicável), resolver litígios e fazer cumprir os nossos acordos e políticas legais.</p>
        <p>A Empresa também reterá os Dados de Utilização para fins de análise interna. Os Dados de Utilização são geralmente retidos por um período de tempo mais curto, exceto quando estes dados são utilizados para reforçar a segurança ou para melhorar a funcionalidade do Nosso Serviço, ou quando somos legalmente obrigados a reter estes dados por períodos de tempo mais longos.</p>
        <h3>Transferência dos seus dados pessoais</h3>
        <p>As suas informações, incluindo os Dados Pessoais, são processadas nos escritórios operacionais da Empresa e em quaisquer outros locais onde as partes envolvidas no processamento estejam localizadas. Isto significa que esta informação pode ser transferida para - e mantida em - computadores localizados fora do seu estado, província, país ou outra jurisdição governamental onde as leis de proteção de dados podem ser diferentes das da sua jurisdição.</p>
        <p>O consentimento do utilizador para a presente Política de Privacidade, seguido do envio dessas informações, representa o seu acordo com essa transferência.</p>
        <p>A Empresa tomará todas as medidas razoavelmente necessárias para garantir que os seus dados sejam tratados de forma segura e de acordo com esta Política de Privacidade e nenhuma transferência dos seus Dados Pessoais terá lugar para uma organização ou um país, a menos que existam controlos adequados em vigor, incluindo a segurança dos seus dados e outras informações pessoais.</p>
        <h3>Eliminar os seus dados pessoais</h3>
        <p>O utilizador tem o direito de apagar ou solicitar a nossa ajuda para apagar os dados pessoais que recolhemos sobre si.</p>
        <p>O nosso Serviço pode dar ao Utilizador a possibilidade de eliminar determinadas informações sobre o Utilizador a partir do Serviço.</p>
        <p>O Utilizador pode atualizar, alterar ou eliminar as suas informações a qualquer momento, iniciando sessão na sua conta, se tiver uma, e visitando a secção de definições da conta que lhe permite gerir as suas informações pessoais. O Utilizador também pode contactar-nos para solicitar o acesso, a correção ou a eliminação de quaisquer informações pessoais que nos tenha fornecido.</p>
        <p>Note-se, no entanto, que poderemos ter de reter determinadas informações quando tivermos uma obrigação legal ou uma base jurídica para o fazer.</p>
        <h3>Divulgação dos seus dados pessoais</h3>
        <h4>Transacções comerciais</h4>
        <p>Se a Empresa estiver envolvida numa fusão, aquisição ou venda de activos, os Seus Dados Pessoais podem ser transferidos. Iremos notificá-lo antes de os seus Dados Pessoais serem transferidos e ficarem sujeitos a uma Política de Privacidade diferente.</p>
        <h4>Aplicação da lei</h4>
        <p>Em determinadas circunstâncias, a Empresa pode ser obrigada a divulgar os seus Dados Pessoais se tal for exigido por lei ou em resposta a pedidos válidos de autoridades públicas (por exemplo, um tribunal ou uma agência governamental).</p>
        <h4>Outros requisitos legais</h4>
        <p>A Empresa pode divulgar os seus Dados Pessoais na convicção de boa fé de que tal ação é necessária para:</p>
        <ul>
        <li>Cumprir uma obrigação legal</li>
        <li>Proteger e defender os direitos ou a propriedade da Empresa</li>
        <li>Prevenir ou investigar possíveis infracções relacionadas com o Serviço</li>
        <li>Proteger a segurança pessoal dos Utilizadores do Serviço ou do público</li>
        <li>Proteger-se contra a responsabilidade legal</li>
        </ul>
        <h3>Segurança dos seus dados pessoais</h3>
        <p>A segurança dos Seus Dados Pessoais é importante para Nós, mas lembre-se que nenhum método de transmissão através da Internet ou método de armazenamento eletrónico é 100% seguro. Embora nos esforcemos por utilizar meios comercialmente aceitáveis para proteger os seus Dados Pessoais, não podemos garantir a sua segurança absoluta.</p>
        <h2>Privacidade das crianças</h2>
        <p>O nosso Serviço não se destina a menores de 13 anos. Não recolhemos intencionalmente informações de identificação pessoal de menores de 13 anos. Se for pai ou tutor e tiver conhecimento de que o seu filho nos forneceu Dados Pessoais, contacte-nos. Se tivermos conhecimento de que recolhemos Dados Pessoais de menores de 13 anos sem verificação do consentimento parental, tomaremos medidas para remover essas informações dos Nossos servidores.</p>
        <p>Se tivermos de nos basear no consentimento como base legal para processar as informações do Utilizador e o país do Utilizador exigir o consentimento de um dos pais, poderemos exigir o consentimento dos pais antes de recolhermos e utilizarmos essas informações.</p>
        <h2>Ligações para outros sítios Web</h2>
        <p>O nosso Serviço pode conter links para outros sites que não são operados por nós. Se o Utilizador clicar numa ligação de terceiros, será direcionado para o site desses terceiros. Aconselhamos vivamente o Utilizador a rever a Política de Privacidade de todos os sites que visitar.</p>
        <p>Não temos qualquer controlo e não assumimos qualquer responsabilidade pelo conteúdo, políticas de privacidade ou práticas de quaisquer sites ou serviços de terceiros.</p>
        <h2>Alterações a esta Política de Privacidade</h2>
        <p>Poderemos atualizar a nossa Política de Privacidade periodicamente. Notificá-lo-emos de quaisquer alterações, publicando a nova Política de Privacidade nesta página.</p>
        <p>Informá-lo-emos por correio eletrónico e/ou através de um aviso bem visível no Nosso Serviço, antes de a alteração entrar em vigor, e actualizaremos a data da “Última atualização” no topo desta Política de Privacidade.</p>
        <p>Aconselhamos o utilizador a rever periodicamente esta Política de Privacidade para verificar se existem alterações. As alterações a esta Política de Privacidade entram em vigor quando são publicadas nesta página.</p>
        <h2>Contate-nos</h2>
        <p>Se tiveres alguma dúvida sobre esta Política de Privacidade, podes entrar em contato conosco:</p>
        <ul>
            <li>
                <p>Por e-mail: pedrofevereiro@embedgaming.pt</p>
            </li>
            <li>
                <p>Visitando a nosso website: <a href="https://embedgaming.pt" rel="external nofollow noopener">https://embedgaming.pt</a></p>
            </li>
            <li>
                <p>Por número de telefone: +351931620761</p>
            </li>
            <li>
                <p>Pelo correio: Rua Particular da Costa, 101</p>
            </li>
        </ul>
    </div>
  );
};

export default PrivacyPolicy;
